<template>
  <div
    class="overlay"
    v-click-outside="closeOverlay"
  >
    <div class="close-search-overlay">
      <NavigationClose
        class="close"
        @click="closeOverlay"
      />
    </div>
    <customer-suggestions
      v-if="searchTermResult?.customerSuggestions?.length > 0"
      :suggestions="searchTermResult.customerSuggestions"
      @suggestion-clicked="openBuyBoxSku"
    />
    <div
      v-if="searchTermResult && searchTermResult.searchResults && searchTermResult.searchResults.length"
      class="overlay__result"
    >
      <div class="overlay__header">
        <div>
          <span v-tv:searchResults="'AmountOfProducts'" />: <span
            v-text="searchTermResult?.count?.toLocaleString(this.$i18n.locale === 'en' ? 'en' : 'de')"
          />
        </div>
      </div>
      <NewSearchOverlayResults
        :products="mappedProducts"
        :l2categories="l2categories"
        :categories="categories"
        :search-term="searchTerm"
        :is-any-search="isAnySearch"
        :search-filter="searchFilter"
        :is-inch-search="isInchSearch"
        @close-overlay="closeOverlay"
        @openBuyBox="openBuyBox"
        @clickItem="clickItem"
      />
    </div>
    <div
      v-else-if="showNoProductsFound"
      class="overlay__noProducts"
    >
      <div
        class="h5"
        v-tv:searchResults="'NoProducts'"
      />
    </div>
    <div
      v-else-if="searchTermResult && !searchTermResult?.customerSuggestions?.length > 0"
      class="overlay__skeleton"
    >
      <SearchSkeleton
        @close="closeOverlay"
      />
    </div>
  </div>
</template>
<script>
import NewSearchOverlayResults from '~/components/search/NewSearchOverlayResults.vue'
import NavigationClose from '~/assets/icons/navigation_close.svg?component'
import SearchSkeleton from '~/components/skeletons/SearchSkeleton.vue'
import {getMappedProducts} from '~/assets/js/utils/product_attributes_mapping'
import {storeToRefs} from 'pinia'
import {useUserStore} from '~/store/user.js'
import CustomerSuggestions from './CustomerSuggestions.vue'

export default {
  components: {
    NavigationClose,
    NewSearchOverlayResults,
    SearchSkeleton,
    CustomerSuggestions
  },
  async setup() {
    const {loadedCustomerUuid} = storeToRefs(useUserStore())

    return {
      currentCustomerUuid: loadedCustomerUuid
    }
  },
  props: {
    searchTermResult: {
      type: Object,
      default () {
        return {}
      }
    },
    l2categories: {
      type: Array,
      default () {
        return []
      }
    },
    categories: {
      type: Array,
      default () {
        return []
      }
    },
    searchTerm: {
      type: String,
      default() {
        return ''
      }
    },
    searchFilter: {
      type: Array,
      default () {
        return []
      }
    },
    isInchSearch: {
      type: Boolean,
      default() {
        return false
      }
    },
    isAnySearch: {
      type: Boolean,
      default () {
        return false
      }
    }
  },
  data() {
    return {
      topResults: []
    }
  },
  methods: {
    closeOverlay(event) {
      const searchElement = document.body.getElementsByClassName('nav__main__searchbar').item(0)
      const target = event?.target
      if (searchElement && target instanceof Node && !searchElement.contains(target)) {
        this.$emit('close-overlay')
      }
    },
    openBuyBox(product) {
      this.$emit('openBuyBox', product)
    },
    openBuyBoxSku({sku, index}) {
      this.$searchInsights?.trackEvent({
        name: 'ClickSuggest', properties: {
          searchServiceName: this.searchTermResult?.azureSearchTelemetry?.searchServiceName,
          searchId: this.searchTermResult?.azureSearchTelemetry?.searchId,
          clickedDocId: sku,
          sku: sku,
          rank: index
        }
      })
      this.$emit('openBuyBoxSku', sku)
    },
    clickItem(sku, rank) {
      this.$emit('clickItem', sku, rank)
      this.$emit('close-overlay')
    }
  },
  computed: {
    mappedProducts() {
      let mappedProducts = getMappedProducts(this.searchTermResult)
      return mappedProducts?.length > 8 ? mappedProducts.slice(0, 8) : mappedProducts
    },
    showNoProductsFound() {
      return this.searchTermResult
        && this.searchTermResult.searchResults
        && this.searchTermResult.searchResults.length === 0
        && (this.currentCustomerUuid !== 'default' ? this.searchTermResult?.customerSuggestions?.length === 0 : true)
    }
  }
}
</script>

<style lang="scss">
.overlay {
  background-color: $color__content--background__secondary;
  padding: $default-margin-padding-width;
  border: 0.063rem solid $color__fst__gray__030;
  box-shadow: 0 0.1875rem 0.375rem $color__fst__gray__29;
  max-height: calc(100vh - 13rem);
  overflow-y: auto;

  .close-search-overlay {
    display: flex;
    justify-content: flex-end;

    .close.icon--svg {
      margin-right: -.5rem;
      margin-top: -.5rem;
      position: absolute;
      font-size: 1.5rem;
      cursor: pointer;
      width: 2rem;
      height: 2rem;
    }
  }

  @include breakpoint-up($lg) {
    width: 64rem;
  }

  &__header {
    display: flex;
    justify-content: space-between;
    font-size: 1rem;
    font-weight: $base-text-font-weight-bold;
    color: $color__fst__primary;
    margin-bottom: .5rem;
  }

  .h5 {
    margin-bottom: .5rem;
  }

  &__skeleton {
    width: 100%;
  }
}
</style>
