import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  height: "24px",
  viewBox: "0 -960 960 960",
  width: "24px",
  fill: "#5f6368",
  class: "icon--svg icon"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", { d: "M410-140v-64.55q0-17.84 9.35-33.26 9.34-15.42 24.65-24.88 44.85-26.62 94.19-40.04 49.35-13.42 101.81-13.42t101.81 13.42q49.34 13.42 94.19 40.04 15.31 9.46 24.65 24.88 9.35 15.42 9.35 33.26V-140H410Zm-310 0v-450l300-225.77 187.31 141.23Q523.84-659 483.08-606.92q-40.77 52.07-40.77 118.84 0 31.77 9.07 60.23 9.08 28.46 26.24 52.46-20.77 8-40.18 16.86-19.4 8.85-38.29 20.3-35.61 21-56.23 57.14-20.61 36.15-20.61 76.63V-140H100Zm540.05-238.08q-45.82 0-77.93-32.07Q530-442.22 530-488.03q0-45.82 32.07-77.93 32.07-32.12 77.88-32.12 45.82 0 77.93 32.07Q750-533.94 750-488.12q0 45.81-32.07 77.93-32.07 32.11-77.88 32.11Z" }, null, -1)
  ])))
}
export default { render: render }