import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  "xmlns:xlink": "http://www.w3.org/1999/xlink",
  width: "1370",
  height: "424.023",
  viewBox: "0 0 1370 424.023",
  class: "icon--svg icon"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("g", null, [
      _createElementVNode("path", {
        id: "Pfad_342",
        "data-name": "Pfad 342",
        d: "M1124.688,201.808c-158.3,0-310.793-12.4-453.688-35.3v48.368a2908.269,2908.269,0,0,0,395.5,26.64c373.827,0,715.138-69.014,974.5-182.651V43.3C1789.723,142.534,1471.228,201.808,1124.688,201.808Z",
        transform: "translate(-671 183)",
        fill: "#486ba0",
        opacity: "0.7"
      }),
      _createElementVNode("path", {
        id: "Pfad_343",
        "data-name": "Pfad 343",
        d: "M2041,41.057V31.9c-237.873,81.83-526.894,129.779-838.543,129.779-187.389,0-366.6-17.315-531.457-48.948v52.028c142.609,22.809,294.726,35.129,452.737,35.129C1470.658,199.889,1789.532,140.452,2041,41.057Z",
        transform: "translate(-671 185)",
        fill: "#a8b8cf",
        opacity: "0.4"
      }),
      _createElementVNode("path", {
        id: "Pfad_464",
        "data-name": "Pfad 464",
        d: "M1127.833,176.389C941.473,179.724,813.894,172.2,671,149.3v32.223c138.023,0,602.261,1,1370,0V-2.631C1751.476,117.438,1495.229,158.378,1127.833,176.389Z",
        transform: "translate(-671 245)",
        fill: "#004388"
      })
    ], -1)
  ])))
}
export default { render: render }