import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  viewBox: "0 0 72 72",
  width: "64px",
  height: "64px",
  class: "icon--svg icon"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", { d: "M24.445 43.857H18.1c-.382 0-.668-.173-.827-.429-.168-.272-.178-.624 0-.966l6.743-11.823c.006-.016.006-.02 0-.036l-4.29-7.379c-.181-.349-.206-.695-.038-.966.159-.262.481-.399.863-.399h6.347c.973 0 1.45.625 1.765 1.186 0 0 4.339 7.516 4.364 7.559-.256.451-6.85 12.036-6.85 12.036C25.853 43.221 25.394 43.857 24.445 43.857zM54.722 15.384L40.673 40.226c-.008.017-.008.03 0 .041l8.945 16.349c.176.352.184.707.014.979C49.468 57.855 49.175 58 48.79 58h-6.338c-.97 0-1.458-.644-1.771-1.212 0 0-8.995-16.497-9.018-16.539C32.112 39.454 45.78 15.21 45.78 15.21 46.12 14.6 46.535 14 47.479 14h6.415c.38 0 .682.143.842.407C54.902 14.677 54.896 15.031 54.722 15.384z" }, null, -1)
  ])))
}
export default { render: render }