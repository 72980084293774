<template>
  <footer class="pure-g pure-u-1">
    <div class="fullsizeimage text-center">
      <div class="hidden-img md:block pure-u-1 fullsizeimage__wave-overlay">
        <WaveBlue
          class="w-full"
          :alt="$tv('waveAltText', 'home')"
        />
      </div>
    </div>
    <div class="footer pure-g -my-2 section--blue-dark py-12">
      <div class="pure-u-1 pure-u-md-1-3 px-sm md:ml-auto md:w-25 footer-section">
        <div>{{ $tv('Sharetext', 'footer') }}</div>
        <div class="share-section">
          <div
            class="icon-wrapper"
            v-for="(icon, index) in footerIcons"
            :key="index"
          >
            <div>
              <a
                :href="icon.url"
                rel="nofollow noopener"
                class="link--no-decoration link link--white"
              >
                <span
                  class="k-icon"
                  :class="icon.icon"
                />
              </a>
            </div>
          </div>
          <div class="share-section__xing">
            <a
              href="https://www.xing.com/pages/fst"
              rel="nofollow noopener"
              class="link--no-decoration link link--white"
            >
              <XingIcon />
            </a>
          </div>
        </div>
      </div>
      <div class="pure-u-1 pure-u-md-1-3 px-sm md:w-25 footer-section">
        <ul>
          <li
            v-for="(item, index) in footerItemsLeft"
            :key="index"
          >
            <a
              :href="item.url[$i18n.locale]"
              target="_blank"
              rel="noopener"
            >
              {{ $tv(item.label, 'footer') }}
            </a>
          </li>
        </ul>
      </div>
      <div class="pure-u-1 pure-u-md-1-3 px-sm md:w-25 md:mr-auto footer-section">
        <ul>
          <li
            v-for="(item, index) in footerItemsRight"
            :key="index"
          >
            <a
              :href="item.url[$i18n.locale]"
              target="_blank"
              rel="noopener"
            >
              {{ $tv(item.label, 'footer') }}
            </a>
          </li>
        </ul>
      </div>
      <div class="pure-u-1 pure-u-md-1-3 px-sm md:w-25 footer-section">
        <div class="contact-us-header">
          {{ $tv('contactUs', 'footer') }}
        </div>
        <ul>
          <li
            v-for="(item, index) in footerItemsFarRight"
            :key="index"
            class="footer-item"
          >
            <span
              v-if="item.icon"
              class="item-icon"
            >
              <component
                :is="item.icon"
                class="want-to-talk-icons"
              />
            </span>
            <a
              class="contact-us-link"
              href="#"
              @click.prevent="handleContactUsClick()"
            >
              {{ $tv(item.label, 'footer') }}
            </a>
          </li>
        </ul>
      </div>
    </div>
  </footer>
</template>

<script>
import WaveBlue from '~/assets/images/wave-blue.svg?component'
import XingIcon from '~/assets/icons/icon-xing.svg?component'
import CommunicationEmail from '~/assets/icons/communication_email.svg?component'
import Feedback from '~/assets/icons/feedback.svg?component'

export default defineComponent({
  name: 'FooterSection',
  components: {
    WaveBlue,
    XingIcon,
    CommunicationEmail,
    Feedback
  },
  setup() {
    return {
      BASE_URL: useRuntimeConfig().public.BASE_URL
    }
  },
  data() {
    return {
      footerItemsLeft: [
        {'label': 'Imprint', 'url': {'de': 'https://www.fst.com/de/footer/impressum/', 'en': 'https://www.fst.com/footer/legal-notice/', 'it': 'https://www.fst.com/footer/legal-notice/', 'fr': 'https://www.fst.com/footer/legal-notice/', 'es': 'https://www.fst.com/footer/legal-notice/'}},
        {'label': 'Cookies', 'url': {'de': 'https://www.fst.com/de/footer/cookies/', 'en': 'https://www.fst.com/footer/cookies/', 'it': 'https://www.fst.com/footer/cookies/', 'fr': 'https://www.fst.com/footer/cookies/', 'es': 'https://www.fst.com/footer/cookies/'}},
        {'label': 'Group', 'url': {'de': 'https://www.fst.com/de/footer/group/', 'en': 'https://www.fst.com/footer/group/', 'it': 'https://www.fst.com/footer/group/', 'fr': 'https://www.fst.com/footer/group/', 'es': 'https://www.fst.com/footer/group/'}}
      ],
      footerItemsRight: [
        {'label': 'Privacy', 'url': {'de': 'https://www.fst.com/de/footer/datenschutzerklaerung/', 'en': 'https://www.fst.com/footer/data-protection-regulations/', 'it': 'https://www.fst.com/footer/data-protection-regulations/', 'fr': 'https://www.fst.com/footer/data-protection-regulations/', 'es': 'https://www.fst.com/footer/data-protection-regulations/'}},
        {'label': 'Terms', 'url': {'de': 'https://www.fst.com/de/footer/allgemeine-geschaeftsbedingungen/', 'en': 'https://www.fst.com/footer/terms-and-conditions/', 'it': 'https://www.fst.com/footer/terms-and-conditions/', 'fr': 'https://www.fst.com/footer/terms-and-conditions/', 'es': 'https://www.fst.com/footer/terms-and-conditions/'}}
      ],
      footerIcons: [
        {'icon': 'k-i-linkedin', 'url': 'https://www.linkedin.com/company/fst?'},
        {'icon': 'k-i-youtube', 'url': 'http://www.youtube.com/channel/UCxx9lK0ANlIjO6gAaaYRjgg'}
      ],
      footerItemsFarRight: [
        {'label': 'Contact', 'icon': 'CommunicationEmail', 'url': null}
        // {'label': 'Feedback', 'icon': 'Feedback', 'url': null} // Wont be used for now just left here to be ready when they want it back #38416 - #38460
      ]
    }
  },

  methods: {
    handleContactUsClick() {
      this.$eventbus.emit('showContactPopup')
    }
  }
})
</script>

<style lang="scss" scoped>
.section--blue-dark {
  background-color: $color__primary;
  color: $color__white;
}

.fullsizeimage img,
.fullsizeimage picture {
  width: 100%;
}

ul {
  margin-bottom: $default-margin-padding-width;
  list-style: none;
  padding: 0;
}

.hidden-img {
  display: none;
}

footer {
  .footer {
    position: relative;
    margin-top: -.3rem;
    z-index: 1;

    .share-section {
      display: flex;
      justify-content: left;

      &__xing {
        font-size: 1.75rem;
        margin-top: 1.5rem;
      }
    }

    .icon-wrapper {
      display: block;
      padding: 0;

      .k-icon {
        font-size: 1.75rem;
      }
    }

    ul {
      margin: 0;
    }

    ul,
    li {
      padding: .0625rem 0;

      a {
        text-decoration: none;
        color: $color__white;
        line-height: 1.5rem;
        transition: box-shadow .2s ease-in-out;

        &:hover {
          box-shadow: inset 0 -.0625rem 0 0 $color--white;
        }
      }
    }
  }

  .monsterheadline {
    font-weight: $base-text-font-weight-bold;
    text-transform: uppercase;
    padding: 0;
    margin: 0 0 1.5rem;
  }

  .footer-section {
    display: flex;
    align-items: center;
    flex-direction: column;

    .item-icon {
      padding-top: .375rem;

      .icon.icon--svg {
        width: 1.25rem;
        height: 1.25rem;
      }
    }

    .contact-us-header {
      padding-bottom: .5rem;
      padding-right: 1.25rem;
    }

    .footer-item {
      display: flex;
      align-items: center;
      gap: .6rem;

      .contact-us-link {
        line-height: 1.125rem;
      }
    }

    .want-to-talk-icons {
      .icon.icon--svg {
        fill: $color__fst__error;
        width: 1.75rem;
        height: 1.75rem;
      }
    }

  }
}

@include breakpoint-down($md) {
  .footer {
    flex-flow: column wrap;
    padding-left: $default-margin-padding-2x;

    div {
      padding-bottom: $default-margin-padding-width;
      font-weight: 100;

      .share-section .icon-wrapper {
        padding-right: .5rem;
      }
    }
  }
}

@include breakpoint-up($md) {
  .lg\:px-64 {
    padding-left: 16rem;
    padding-right: 16rem;
  }

  footer {
    .footer {
      .icon-wrapper {
        margin: 1.5rem 0 0;
        padding-right: $default-margin-padding-width;
      }
    }

    .monsterheadline {
      font-size: 5rem;
      color: $color__white;
      line-height: 5rem;
    }
  }

  .fullsizeimage {
    position: relative;
    display: block;
    background-size: cover;
    background-position: 50%;
    background-repeat: no-repeat;
    width: 100%;
    height: 0;
  }

  .fullsizeimage__wave-overlay {
    position: absolute;
    bottom: 0;
    pointer-events: none;

    svg.icon--svg {
      width: 100%;
    }
  }
}

</style>
