<template>
  <PopupBase
    :show="show"
    @close-pop-up="closePopUp"
    class="regionPopup"
  >
    <div
      class="h5"
      v-tv:regions="'headline'"
    />
    <div
      class="regionPopup__description"
      v-tv:regions="'description'"
    />
    <div
      class="regionPopup__urlRegion"
    >
      {{ urlRegion }}
    </div>
    <div
      class="regionPopup__selectRegion"
      v-tv:regions="'selectRegion'"
    />
    <div
      class="regionPopup__regions"
    >
      <div
        v-for="region in regions"
        :key="region.value"
        class="regionTile"
        :class="{ selected: region.value === defaultRegion }"
        @click="switchRegion(region)"
      >
        <component
          :is="getIconComponent(region.value)"
          class="icon"
        />
        <span
          class="regionName"
        >
          {{ region.label }}
        </span>
      </div>
    </div>
  </PopupBase>
</template>

<script>
import PopupBase from './PopupBase.vue'
import IconRegionNA from '~/assets/icons/icon_na.svg?component'
import IconRegionEU from '~/assets/icons/icon_eu.svg?component'
import IconRegionGLOBAL from '~/assets/icons/icon_global.svg?component'
import IconRegionSEA from '~/assets/icons/icon_sea.svg?component'

export default {
  components: {
    PopupBase,
    IconRegionNA,
    IconRegionEU,
    IconRegionGLOBAL,
    IconRegionSEA
  },
  name: 'PopupRegions',
  data() {
    return {
      show: false,
      defaultRegion: '',
      urlRegion: ''
    }
  },
  mounted() {
    if (!this.$auth.loggedIn) {
      const urlRegion = this.$globalization.getRegionFromURL()?.toLowerCase()
      const cookieRegion = this.$globalization.getRegionFromCookie()?.toLowerCase()
      const cookieFdRegion = this.$globalization.getFdRegionFromCookie()?.toLowerCase()
      if (!cookieRegion && urlRegion && urlRegion !== cookieFdRegion) {
        this.urlRegion = this.$tv(this.$config.public.regions?.find(r => r.RegionShortName.toLowerCase() === urlRegion)?.RegionName, 'regions')
        this.defaultRegion = urlRegion?.toUpperCase()
        this.show = true
      } else if (!cookieRegion && urlRegion) {
        this.$globalization.switchRegion(urlRegion?.toUpperCase())
      } else if (cookieRegion) {
        this.$globalization.switchRegion(cookieRegion?.toUpperCase())
      }
    }
  },
  methods: {
    switchRegion(event) {
      this.show = false
      this.$globalization.switchRegion(event.value)

    },
    closePopUp() {
      const urlRegion = this.$globalization.getRegionFromURL()
      this.$globalization.setRegion(urlRegion.toUpperCase())
      this.show = false
    },
    getIconComponent(region) {
      switch (region) {
      case 'NA':
        return 'IconRegionNA'
      case 'EU':
        return 'IconRegionEU'
      case 'GLOBAL':
        return 'IconRegionGLOBAL'
      case 'SEA':
        return 'IconRegionSEA'
      default:
        return 'IconRegionNA'
      }
    }
  },
  computed: {
    regions() {
      return (
        (this.$i18n.locale &&
          this.$config.public.regions?.map(l => ({
            label: this.$tv(l.RegionName, 'regions'),
            value: l.RegionShortName,
            icon: l.RegionShortName
          }))) ||
        []
      )
    }
  }
}
</script>

<style lang="scss">
.regionPopup {
  .popup__wrapper .popup__content {
    padding: 1rem;
  }

  &__description {
    margin-top: 1rem;
    margin-bottom: .25rem;
    font-weight: $base-text-font-weight-light;
  }

  &__selectRegion {
    margin-top: 1.5rem;
    margin-bottom: 1rem;
    font-weight: $base-text-font-weight-light;
  }

  &__regions {
    display: flex;
    gap: .5rem;
    flex-wrap: wrap;

    .regionTile {
      display: flex;
      flex-direction: column;
      width: 9.75rem;
      box-shadow: 0 .1875rem .375rem 0 $color__fst__gray__29;
      align-items: center;
      cursor: pointer;

      &.selected {
        border: .125rem solid $color__fst__primary;
      }

      .icon {
        padding: .8125rem 0;
        font-size: 6.25rem;
      }

      .regionName {
        font-size: $base-text-font-size;
        font-weight: $base-text-font-weight-bold;
        color: $color__fst__primary;
        text-align: center;
        width: 100%;
        border-top: 0.0625rem solid $color__fst__gray__020;
        padding: .35rem 0;
      }
    }
  }
}
</style>
